@keyframes JoinLetter {
	0% {
		opacity: 0;
		letter-spacing: 1.6rem;
	}

	100% {
		opacity: 1;
		letter-spacing: 0.2rem;
	}
}
