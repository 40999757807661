@import 'colors';
@import 'fonts';
@import 'media';
@import 'mixin';
@import 'fonts';
@import 'animate';

@tailwind base;
@tailwind components;
@tailwind utilities;
